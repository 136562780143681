<template>
  <div>
    <h4>
      <b-badge v-if="userData" variant="secondary">
        <feather-icon
            icon="StarIcon"
            class="mr-25"
        />
        <span >{{ userData.company_name }}</span>
      </b-badge>
      <b-badge class="ml-25" variant="primary" v-if="userData & userData.register_country">
        <span >{{ userData.register_country.emoji }} {{ userData.register_country.name }}</span>
      </b-badge>
      <b-badge class="ml-25" variant="warning" v-if="userData">
        <span v-if="userData.plan">{{ userData.plan.plan_tag.toUpperCase() }}</span>
        <span> | </span>
        <span v-if="userData.plan_period "> {{ userData.plan_period }} </span>
        <span> | </span>
        <span v-if="userData.plan">{{ userData.plan.currency.currency_symbol }}</span>
      </b-badge>
    </h4>
    <b-row>
      <b-col
          cols="12"
      >
        <b-tabs>
          <b-tab>
            <template #title>
              <feather-icon icon="UsersIcon" />
              <span>Kullancılar</span>
            </template>
            <customer-user-list-cart v-if="userData" :customer_id="userData.id" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="MapPinIcon" />
              <span>Merkezler</span>
            </template>
<!--            <customer-center-list-cart :customer_id="userData.id" />-->
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="KeyIcon" />
              <span>Lisans Yönetimi</span>
            </template>

            <b-card-text>
              Chocolate croissant cupcake croissant jelly donut. Cheesecake toffee apple pie chocolate bar biscuit tart croissant. Lemon drops danish cookie. Oat cake macaroon icing tart lollipop cookie sweet bear claw.
            </b-card-text>
            <b-card-text>
              Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies dragée. Chocolate bar liquorice cheesecake cookie chupa chups marshmallow oat cake biscuit. Dessert toffee fruitcake ice cream powder tootsie roll cake.
            </b-card-text>
          </b-tab>
          <b-tab disabled>
            <template #title>
              <feather-icon icon="EyeOffIcon" />
              <span>Disabled</span>
            </template>

            <b-card-text>
              Halvah dessert fruitcake toffee oat cake tart oat cake topping jelly beans. Pudding sweet pie pastry lemon drops jujubes danish pie gingerbread. Liquorice powder wafer.
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BAlert,
  BCard, BModal, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BTabs, BTab,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BCardHeader, BCardBody, VBModal, BCardText, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import flatPickr from 'vue-flatpickr-component'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Multiselect from 'vue-multiselect'
import VueTelInput from 'vue-tel-input'
import { ref } from '@vue/composition-api'
import router from '@/router'
import CustomerUserListCart from '@/views/custom_app/components/customer/CustomerUserListCart.vue'
import CustomerCenterListCart from "@/views/custom_app/admin/components/customer/CustomerCenterListCart.vue"

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BModal,
    BAlert,
    BTabs,
    BTab,
    BCardHeader,
    BCardBody,
    BCard,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BCardText,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    flatPickr,
    DateRangePicker,
    vSelect,
    Multiselect,
    VueTelInput,
    CustomerUserListCart,
    quillEditor,
    CustomerCenterListCart,

  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      temp_note: null,
      userData: null,
    }
  },
  watch: {
  },
  created() {
    useJwt.getCustomerDetail(router.currentRoute.params.id)
      .then(response => {
        this.userData = response.data
      })
      .catch(error => {
        this.userData = undefined
        console.log(error.response)
      })
  },
  methods: {
    show_note_update_modal() {
      this.temp_note = this.userData.note
      this.$refs.note_update_modal.show()
    },
    update_note_button() {
      const formData = new FormData()
      formData.append('note', this.temp_note)
      useJwt.updateCustomer(router.currentRoute.params.id, formData)
        .then(response => {
          this.userData = response.data
        })
        .catch(error => {
          this.userData = undefined
          console.log(error.response)
        })
      this.$refs.note_update_modal.hide()
    },
    close_note_update_modal() {
      this.$refs.note_update_modal.hide()
    },
  },
  setup() {

  },
}
</script>
<style lang="scss">
</style>
