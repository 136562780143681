<template>
  <div>
    <!--    Ekle Modal-->
    <b-modal
      id="new_record_modal"
      ref="new_record_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_add')"
      footer-class="d-none"
      size="md"
      @hide="close_new_record_modal"
    >
      <div>
        <validation-observer
          ref="addForm"
          #default="{invalid}"
        >
          <b-form @submit.prevent="new_record_add_button">
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="name"><span class="text-danger text-bold">*</span> {{ $t('user_management.full_name') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="name"
                      :state="errors.length > 0 ? false: name != null ? true : null"
                      :placeholder="$t('user_management.full_name')"
                      name="name"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <!-- phone number -->
                <b-form-group>
                  <label for="phone_number"><span class="text-danger text-bold">*</span> {{ $t('register_page.lbl_phone_number') }}</label>
                  <!-- TODO zorunlu alan button ile entegre çalışmıoyor. Hata var!!             -->
                  <validation-provider
                    #default="{ errors }"
                    name="phone_number"
                    rules="required"
                  >
                    <vue-tel-input
                      id="phone_number"
                      v-model="phone_number"
                      mode="international"
                      :default-country="countryCode"
                      :input-options="custom_inputOptions"
                      @validate="phone_number_check_valid"
                    >
                      <template v-slot:arrow-icon>
                        <span /></template>
                    </vue-tel-input>
                    <small
                      v-if="phone_number_is_valid==false"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mb-md-0 mb-2"
              >
                <b-form-group>
                  <label for="email"><span class="text-danger text-bold">*</span> {{ $t('user_management.email') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="email"
                      :placeholder="$t('user_management.email')"
                      :state="errors.length > 0 ? false: email != null ? true : null"
                      name="email"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="password_reset_mail===false"
                cols="12"
              >
                <b-form-group>
                  <label for="parola"><span class="text-danger text-bold">*</span> {{ $t('user_management.password') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        id="reset-password-new"
                        v-model="user_password"
                        :state="errors.length > 0 ? false: user_password != null ? true : null"
                        :type="password1FieldType"
                        name="reset-password-new"
                        :placeholder="$t('user_management.password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="RefreshCwIcon"
                          @click="generatePassword"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('core.required') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <hr>
              <b-col
                cols="12"
                class="mb-md-0"
              >
                <b-form-checkbox
                  v-model="password_reset_mail"
                  true-value="true"
                  false-value="false"
                  plain
                >
                  {{ $t('user_management.email_password_creation_link') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <hr>
            <b-row class="justify-content-end">
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                type="submit"
                :disabled="invalid"
              >
                <span class="align-middle">{{ $t('core.btn_add') }}</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Güncelle Modal-->
    <b-modal
      id="update_record_modal"
      ref="update_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_update')"
      footer-class="d-none"
      size="lg"
      @hide="close_edit_record_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label for="name"><span class="text-danger text-bold">*</span> {{ $t('user_management.full_name') }}</label>
              <b-form-input
                v-model="edit_name"
                :placeholder="$t('user_management.full_name')"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <!-- phone number -->
            <b-form-group>
              <label for="phone_number"><span class="text-danger text-bold">*</span> {{ $t('register_page.lbl_phone_number') }}</label>
              <!-- TODO zorunlu alan button ile entegre çalışmıoyor. Hata var!!             -->
              <validation-provider
                #default="{ errors }"
                name="phone_number"
                rules="required"
              >
                <vue-tel-input
                  id="phone_number"
                  v-model="edit_phone_number"
                  mode="international"
                  :default-country="countryCode"
                  :input-options="custom_inputOptions"
                  @validate="edit_phone_number_check_valid"
                >
                  <template v-slot:arrow-icon>
                    <span /></template>
                </vue-tel-input>
                <small
                  v-if="edit_phone_number_is_valid==false"
                  class="text-danger"
                >{{ $t('core.required') }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group>
              <label for="email"><span class="text-danger text-bold">*</span> {{ $t('user_management.email') }}</label>
              <b-form-input
                v-model="edit_email"
                placeholder="E-posta"
                name="email"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0"
          >
            <b-form-checkbox
              v-model="edit_password_reset_mail"
              true-value="true"
              false-value="false"
              plain
            >
              {{ $t('user_management.email_password_creation_link') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            variant="success"
            size="sm"
            class="mr-1"
            @click="record_edit_button()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-25"
            />
            <span class="align-middle">{{ $t('core.btn_update') }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Güncelle Modal-->

    <!--    Sil Modal-->
    <b-modal
      id="delete_record_modal"
      ref="delete_record_modal"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_delete')"
      footer-class="d-none"
      size="lg"
      @hide="close_delete_record_modal"
    >
      <div>

        <b-card-text class="text-left">
          <span>{{ $t('user_management.delete_question',{selected_row_name: selected_row_name,}) }}</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
            variant="success"
            size="sm"
            class="mr-1"
            @click="close_delete_record_modal()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span class="align-middle">{{ $t("core.btn_cancel") }}</span>
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="mr-1"
            @click="record_delete_button()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-26"
            />
            <span class="align-middle">{{ $t("core.btn_delete") }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Sil Modal-->

    <!--    Admin Yetkisi Ver Modal-->
    <b-modal
      id="give_permission_modal"
      ref="give_permission_modal"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      :title="$t('user_management.modal_titile_set_admin_privilege')"
      footer-class="d-none"
      size="lg"
      @hide="close_give_permission_modal"
    >
      <div>

        <b-card-text class="text-left">
          <span> {{ $t("user_management.warning_message_set_admin_privilege", {selected_row_name:selected_row_name}) }}</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
            variant="success"
            size="sm"
            class="mr-1"
            @click="close_give_permission_modal()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span class="align-middle">{{ $t("core.btn_cancel") }}</span>
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="give_admin_permission_button()"
          >
            <feather-icon
              icon="StarIcon"
              class="mr-25"
            />
            <span class="align-middle">{{ $t("user_management.btn_admin_privilege") }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Admin Yetkisi Ver Modal-->

    <!--    Admin Yetkisi Sil Modal-->
    <b-modal
      id="revoke_permission_modal"
      ref="revoke_permission_modal"
      class="danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      :title="$t('user_management.warning_message_delete_admin_privilege',{selected_row_name:selected_row_name})"
      footer-class="d-none"
      size="lg"
      @hide="close_revoke_permission_modal"
    >
      <div>

        <b-card-text class="text-left">
          <span>{{ $t("user_management.warning_message_delete_admin_privilege", {"selected_row_name": selected_row_name}) }}</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
            variant="success"
            size="sm"
            class="mr-1"
            @click="close_revoke_permission_modal()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span class="align-middle">{{ $t("core.btn_cancel") }}</span>
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="revoke_admin_permission_button()"
          >
            <feather-icon
              icon="UserXIcon"
              class="mr-25"
            />
            <span class="align-middle">{{ $t("user_management.btn_delete_admin_privilege") }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Admin Yetkisi Sil Modal-->

    <b-card>
      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >

          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />

        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              size="sm"
              class="d-inline-block mr-1"
              :placeholder="$t('core.search')"
            />
            <b-button
              v-b-modal.new_record_modal
              size="sm"
              variant="primary"
            >
              <span class="text-nowrap">{{ $t('core.btn_new_add') }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-50">
        <b-table
          responsive
          :items="table_data"
          :fields="tableColumns"
          primary-key="id"
          class="text-nowrap"
          style="min-height: 400px !important;"
          show-empty
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :empty-text="$t('core.no_records_found')"
          small
        >
          <!-- Column: customer user full name -->
          <template #cell(user.name)="data">
            <feather-icon
              v-if="$can('manage', 'user_management')&&data.item.role=='admin'"
              icon="StarIcon"
              style="color: red;"
              class="mr-25"
            />
            <span>{{ data.item.user.name }}</span>
          </template>

          <!-- Column: email -->
          <template #cell(user.email)="data">
            <b-badge
              pill
              variant="light-secondary"
            >
              <feather-icon icon="MailIcon" /> {{ data.item.user.email }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(id)="data">
            <div class="text-nowrap">
              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t("core.btn_update") }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="rowDeleteModal(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t("core.btn_delete") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('manage', 'user_management')&&data.item.role=='user'"
                  @click="rowGivePermissionModal(data.item.id)"
                >
                  <feather-icon icon="StarIcon" />
                  <span class="align-middle ml-50">{{ $t("user_management.modal_titile_set_admin_privilege") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('manage', 'user_management')&&data.item.role=='admin'"
                  @click="rowRevokePermissionModal(data.item.id)"
                >
                  <feather-icon icon="UserXIcon" />
                  <span class="align-middle ml-50">{{ $t("user_management.modal_titile_delete_admin_privilege") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="loginascompanyuser(data.item.id)"
                  v-if="$can('manage', 'admin')"
                >
                  <feather-icon icon="RepeatIcon" />
                  <span class="align-middle ml-50">{{ $t("user_management.login_as_user") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-row>
      <div class="card-footer">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
  BModal,
  BForm,
  BCardHeader,
  BCardBody,
  BLink,
  BFormInput,
  BTable,
  BBadge,
  BCardText,
  BFormGroup,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BPagination, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import router from '@/router'
import ability from "@/libs/acl/ability";
import axios from "axios";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BCardHeader,
    BCardBody,
    BLink,
    BFormInput,
    BTable,
    BFormCheckbox,
    BBadge,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    VueTelInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    customer_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected_row_name: null,
      selected_row_id: null,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      roleOptions: [],
      table_data: null,
      tableColumns: [
        { key: 'user.name', label: this.$t('user_management.full_name'), sortable: true },
        { key: 'user.phone_number', label: this.$t('user_management.phone_number'), sortable: true },
        { key: 'user.email', label: this.$t('user_management.email'), sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      name: null,
      email: null,

      custom_inputOptions: {
        placeholder: '',
      },
      phone_number: null,
      phone_number_object: null,
      phone_number_is_valid: null,
      countryCode: '',

      user_password: null,
      password_reset_mail: false,
      password1FieldType: 'password',
      edit_name: null,
      edit_email: null,
      edit_password_reset_mail: false,
      edit_phone_number: null,
      edit_phone_number_object: null,
      edit_phone_number_is_valid: null,

    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    sortBy: { handler() { this.getTableData() } },
    isSortDirDesc: { handler() { this.getTableData() } },
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
  },
  setup() {

  },
  mounted() {
    this.getCountryCode()
  },
  methods: {
    async getCountryCode() {
      try {
        const response = await axios.get('http://ip-api.com/json/')
        this.countryCode = response.data.countryCode
      } catch (error) {
        console.error('Failed to get country code:', error)
      }
    },
    phone_number_check_valid(aaa) {
      this.phone_number_is_valid = aaa.valid
      this.phone_number_object = aaa
    },
    edit_phone_number_check_valid(aaa) {
      this.edit_phone_number_is_valid = aaa.valid
      this.edit_phone_number_object = aaa
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    generatePassword() {
      const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const passwordLength = 12
      let password = ''
      for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length)
        password += chars.substring(randomNumber, randomNumber + 1)
      }
      this.user_password = password
    },
    getTableData() {
      useJwt.getCustomerUserList({
        // center_type: this.filter_center_type.value,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        company: this.customer_id,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowUpdateModal(row_id) {
      useJwt.customerUserDetail(row_id)
        .then(response => {
          this.edit_name = response.data.user.name
          this.edit_email = response.data.user.email
          this.edit_phone_number = response.data.user.phone_number
          this.selected_row_id = response.data.id
          this.$refs.update_record_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowDeleteModal(row_id) {
      useJwt.customerUserDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.user.name
          this.selected_row_id = response.data.id
          this.$refs.delete_record_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    rowGivePermissionModal(row_id) {
      useJwt.customerUserDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.user.name
          this.selected_row_id = response.data.id
          this.$refs.give_permission_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    rowRevokePermissionModal(row_id) {
      useJwt.customerUserDetail(row_id)
        .then(response => {
          this.selected_row_name = response.data.user.name
          this.selected_row_id = response.data.id
          this.$refs.revoke_permission_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    loginascompanyuser(user_id) {
      const formData = new FormData()
      formData.append('user_id', user_id)
      useJwt.loginascompanyuser(formData)
        .then(response => {
          // Admin token verileri set ediliyor
          localStorage.setItem('tempAccessToken', localStorage.getItem('accessToken'))
          localStorage.setItem('tempRefreshToken', localStorage.getItem('refreshToken'))
          // Admin token verileri set ediliyor

          // Kullanıcı tokenları set ediliyor
          localStorage.setItem('accessToken', response.data.access)
          localStorage.setItem('refreshToken', response.data.refresh)
          // Kullanıcı tokenları set ediliyor
          const { userData } = response.data
          localStorage.setItem('userData', JSON.stringify(userData))
          ability.update(userData.ability)
          router.push({ name: 'dashboard' })
        })
        .catch(error => {
          console.log(error)
        })
    },
    new_record_add_button() {
      const formData = new FormData()
      formData.append('customer', this.customer_id)
      formData.append('name', this.name)
      formData.append('phone_number', this.phone_number_object.number)
      formData.append('email', this.email)
      formData.append('password', this.user_password)
      formData.append('password_reset_mail', this.password_reset_mail)

      useJwt.createCustomerUser(formData)
        .then(response => {
          this.getTableData()
          this.close_new_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Eklendi',
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
          console.log(error.response)
        })
    },
    record_edit_button() {
      const formData = new FormData()
      formData.append('name', this.edit_name)
      formData.append('email', this.edit_email)
      formData.append('phone_number', this.edit_phone_number_object.number)
      formData.append('password_reset_mail', this.edit_password_reset_mail)
      useJwt.updateCustomerUser(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_edit_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
    give_admin_permission_button() {
      const formData = new FormData()
      formData.append('admin_status', true)
      useJwt.adminPermissionCustomerUser(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_give_permission_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
    revoke_admin_permission_button() {
      const formData = new FormData()
      formData.append('admin_status', false)
      useJwt.adminPermissionCustomerUser(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_revoke_permission_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
    record_delete_button() {
      useJwt.customerUserDelete(this.selected_row_id)
        .then(response => {
          this.getTableData()
          this.close_delete_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Silindi',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
    close_new_record_modal() {
      this.$refs.new_record_modal.hide()
      this.name = null
      this.email = null
      this.phone_number = null
      this.phone_number_object = null
      this.phone_number_is_valid = null
      this.user_password = null
      this.password_reset_mail = false
      this.password1FieldType = 'password'
    },
    close_edit_record_modal() {
      this.$refs.update_record_modal.hide()
      this.edit_yas_araligi = null
      this.edit_phone_number = ''
      this.edit_phone_number_object = null
      this.edit_phone_number_is_valid = null
      this.selected_row_id = null
    },
    close_delete_record_modal() {
      this.$refs.delete_record_modal.hide()
      this.selected_row_id = null
    },
    close_give_permission_modal() {
      this.$refs.give_permission_modal.hide()
      this.selected_row_id = null
    },
    close_revoke_permission_modal() {
      this.$refs.revoke_permission_modal.hide()
      this.selected_row_id = null
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
