<template>
  <div>
    <!--    Ekle Modal-->
    <b-modal
      id="new_record_modal"
      ref="new_record_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      :title="$t('core.model_title_add')"
      footer-class="d-none"
      size="md"
      @hide="close_new_record_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Merkez Adı"
              label-for="center_name"
            >
              <b-form-input
                v-model="center_name"
                placeholder="Merkez Adı"
                name="center_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Açıklama"
              label-for="note"
            >
              <b-form-input
                v-model="note"
                placeholder="Açıklama"
                name="note"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            size="sm"
              variant="primary"
            class="mr-1"
            @click="new_record_add_button"
          >

            <span class="align-middle">{{ $t('core.btn_add') }}</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Güncelle Modal-->
    <b-modal
        id="update_record_modal"
        ref="update_record_modal"
        centered

        no-close-on-backdrop
        content-class="shadow"
        title="Kayıt Düzenle"
        footer-class="d-none"
        size="lg"
        @hide="close_edit_record_modal"
    >
      <div>
        <b-row>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Merkez Adı"
                label-for="center_name"
            >
              <b-form-input
                  v-model="edit_center_name"
                  placeholder="Merkez Adı"
                  name="center_name"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Açıklama"
                label-for="note"
            >
              <b-form-input
                  v-model="edit_note"
                  placeholder="Açıklama"
                  name="note"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-success"
              class="mr-1"
              @click="record_edit_button()"
          >
            <feather-icon
                icon="SaveIcon"
                class="mr-1"
            />
            <span class="align-middle">Güncelle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Güncelle Modal-->

    <!--    Sil Modal-->
    <b-modal
        id="delete_record_modal"
        ref="delete_record_modal"
        class="danger"
        centered

        no-close-on-backdrop
        content-class="shadow"
        title="Kayıt Sil"
        footer-class="d-none"
        size="lg"
        @hide="close_delete_record_modal"
    >
      <div>

        <b-card-text class="text-left">
          <span class="text-danger">{{ selected_row_name }}</span><span>isimli kaydı silmek istediğinize emin misiniz?</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-success"
              class="mr-1"
              @click="close_delete_record_modal()"
          >
            <feather-icon
                icon="XIcon"
                class="mr-1"
            />
            <span class="align-middle">İptal</span>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-danger"
              class="mr-1"
              @click="record_delete_button()"
          >
            <feather-icon
                icon="TrashIcon"
                class="mr-1"
            />
            <span class="align-middle">Sil</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Sil Modal-->

    <b-card>
      <div class="mb-1">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                size="sm"
                class="d-inline-block mr-1"
                :placeholder="$t('core.search')"
              />
              <b-button
                v-b-modal.new_record_modal
                size="sm"
                variant="primary"
              >
                <span class="text-nowrap">{{ $t('core.btn_new_add') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        responsive
        :items="table_data"
        :fields="tableColumns"
        primary-key="id"
        class="text-nowrap"
        show-empty
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :empty-text="$t('core.no_records_found')"
      >
        <!-- Column: Actions -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Düzenle</span>
              </b-dropdown-item>
              <b-dropdown-item @click="rowDeleteModal(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Sil</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
  BModal,
  BCardHeader,
  BCardBody,
  BLink,
  BFormInput,
  BTable,
  BBadge,
  BCardText,
  BFormGroup,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BPagination, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BCardHeader,
    BCardBody,
    BLink,
    BFormInput,
    BTable,
    BFormCheckbox,
    BBadge,
    BCardText,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    customer_id: {
      type: String,
      required: true,
    },
    center_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selected_row_name: null,
      selected_row_id: null,
      perPageOptions: [25, 50, 100],
      searchQuery: null,
      sortBy: null,
      isSortDirDesc: null,
      roleOptions: [],
      table_data: null,
      tableColumns: [
        { key: 'devices_name', label: 'Cihaz Adı', sortable: true },
        { key: 'note', label: 'Not', sortable: true },
        { key: 'device_type.device_type_name', label: 'Cihaz Tirü', sortable: true },
        { key: 'device_analyzer.device_analyzer', label: 'Analizör Türü', sortable: true },
        { key: 'device_brand.device_brand', label: 'Marka', sortable: true },
        { key: 'connection_status', label: 'Bağlantı Durumu', sortable: true },
        { key: 'status', label: 'Durum', sortable: true },
        { key: 'version', label: 'Versiyon', sortable: true },
        { key: 'serial_number', label: 'Seri No', sortable: true },
        { key: 'ip_address', label: 'İp Adresi', sortable: true },
        { key: 'created_date', label: 'Oluşturulma Tarihi', sortable: true },
        { key: 'control_date', label: 'Kontrol Tarihi', sortable: true },
        { key: 'device_port', label: 'Cihaz Port', sortable: true },
        { key: 'current_transformer', label: 'Akım Trafosu Kay Sayı', sortable: true },
        { key: 'voltage_transformer', label: 'Gerilim Trafo Kat Sayı', sortable: true },
        { key: 'installed_power', label: 'Kurulu GÜç', sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      center_name: null,
      edit_center_name: null,

    }
  },
  computed: {
  },
  watch: {
    sortBy: {handler() {this.getTableData()},},
    isSortDirDesc: {handler() {this.getTableData()},},
    currentPage: {
      handler() {
        this.getTableData()
      },
    },
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
  },
  setup() {

  },
  methods: {
    getTableData() {
      useJwt.getCompanyCenterDevicesList({
        // center_type: this.filter_center_type.value,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        company: this.customer_id,
        companycenter: this.center_id,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowUpdateModal(row_id) {
      useJwt.detailCompanyCenterDevices(row_id)
        .then(response => {
          this.edit_center_name = response.data.center_name
          this.edit_note = response.data.note
          this.selected_row_id = response.data.id
          this.$refs.update_record_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    rowDeleteModal(row_id) {
      useJwt.detailCompanyCenterDevices(row_id)
        .then(response => {
          this.selected_row_name = response.data.devices_name
          this.selected_row_id = response.data.id
          this.$refs.delete_record_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    new_record_add_button() {
      const formData = new FormData()
      formData.append('company', this.customer_id)
      formData.append('center_name', this.center_name)
      formData.append('note', this.note)

      useJwt.createCompanyCenterDevices(formData)
        .then(response => {
          this.getTableData()
          this.close_new_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Eklendi',
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.log(error.response)
        })
    },
    record_edit_button() {
      const formData = new FormData()
      formData.append('center_name', this.edit_center_name)
      formData.append('note', this.edit_note)
      useJwt.updateCompanyCenterDevices(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_edit_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_delete_button() {
      useJwt.deleteCompanyCenterDevices(this.selected_row_id)
        .then(response => {
          this.getTableData()
          this.close_delete_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Silindi',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_new_record_modal() {
      this.$refs.new_record_modal.hide()
      this.center_name = null
      this.note = null
    },
    close_edit_record_modal() {
      this.$refs.update_record_modal.hide()
      this.edit_center_name = null
      this.edit_note = null
      this.selected_row_id = null
    },
    close_delete_record_modal() {
      this.$refs.delete_record_modal.hide()
      this.selected_row_id = null
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
